import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  fetchBatchesByQuery(filter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.outboundShipping.Controller.getBatchesByQuery(pageable), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  fetchCountsBatchesByQuery(filter) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.outboundShipping.Controller.getCountsBatchesByQuery(), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  fetchAllItemsByBatchId(query, pageable = 'page=0&size=500') {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.outboundShipping.Controller.findAllItemsByBatchId(query, pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  fetchAllByMultipleBatchIds(batches) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.outboundShipping.Controller.findAllByMultipleBatchIds(), batches)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  getILFormattedItemsBatchIds(data) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.outboundShipping.Controller.getILFormattedItemsBatchIds(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async shipmentBatchStats(batchId) {
    const result = await axios.get(endpoints.outboundShipping.Controller.shipmentBatchStats(batchId))
    return result
  },
  async createBatch(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.outboundShipping.Controller.createBatch(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async createBatchAmazon(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.outboundShipping.Controller.createBatchAmazon(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  addCostToShipmentBatch(data) {
    return new Promise((resolve, reject) => {
      axios.post(endpoints.outboundShipping.Controller.addCostToShipmentBatch(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateCostToShipmentBatch(data) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.updateCostToShipmentBatch(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  setShipmentPalletDetailsAndTransmitToAmazon(data) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.setShipmentPalletDetailsAndTransmitToAmazon(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getShipmentPalletByBatchId(batchId) {
    return new Promise((resolve, reject) => {
      axios.get(endpoints.outboundShipping.Controller.getShipmentPalletByBatchId(batchId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  markAsShipped(id) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.markAsShipped(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateBatch(data) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.outboundShipping.Controller.updateBatch(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  deleteBatch(id) {
    return new Promise((resolve, reject) => {
      axios.delete(endpoints.outboundShipping.Controller.deleteBatch(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  async findBoxesByQuery(filter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.outboundShipping.Controller.findBoxesByQuery(pageable), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async createShipmentPackages(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.outboundShipping.Controller.createShipmentPackages(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async fetchFBAItemsByProductId(productId, pageable) {
    const result = await axios.get(endpoints.outboundShipping.Controller.getFBAItemsByProductId(productId, pageable))
    return result
  },
  async getOtherItemsByProductId(productId, pageable) {
    const result = await axios.get(endpoints.outboundShipping.Controller.getOtherItemsByProductId(productId, pageable))
    return result
  },
  async getOpenParentBatches(channel) {
    const query = channel ? `/${channel}` : ''
    const result = await axios.get(endpoints.outboundShipping.Controller.getOpenParentBatches(query))
    return result.data.body
  },
  async getMissingReferenceIds() {
    const result = await axios.get(endpoints.outboundShipping.Controller.getMissingReferenceIds())
    return result.data.body
  },
  async queryMerchantShipments(data, pageable) {
    const result = await axios.post(endpoints.outboundShipping.Controller.queryMerchantShipments(pageable), data)
    return result.data.body
  },
  async listMerchantShipmentItems(shipmentId) {
    const result = await axios.get(endpoints.outboundShipping.Controller.listMerchantShipmentItems(shipmentId))
    return result.data.body
  },
}
